<template>
  <header
    v-if="
      currentRouteName != 'home' &&
      currentRouteName != 'old' &&
      currentRouteName != '404'
    "
    class="w-full h-12 flex justify-start pl-4 border-b border-[#ec6608]"
  >
    <ul
      class="flex justify-around w-full max-w-6xl m-auto items-center font-bold text-sm sm:text-base uppercase"
    >
      <li>
        <router-link to="/"
          ><i class="fa-solid fa-house mr-1"></i>
          <span class="hidden sm:inline-block">Accueil</span></router-link
        >
      </li>

      <li>
        <router-link to="/contact"
          ><i class="fa-regular fa-envelope hidden sm:inline-block mr-1"></i
          >Contact</router-link
        >
      </li>
      <li>
        <a
          :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${operationId}/produits.pdf`"
          target="_blank"
          ><i class="fa-solid fa-list-check mr-1 hidden sm:inline-block"></i>
          <span class="hidden sm:inline-block">Produits éligibles</span
          ><span class="sm:hidden">Nos produits</span></a
        >
      </li>
      <li>
        <a
          :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${operationId}/reglement.pdf`"
          target="_blank"
          ><i
            class="fa-solid fa-scale-balanced hidden sm:inline-block mr-1"
          ></i>
          Règlement</a
        >
      </li>
      <li v-if="homelink != '/'">
        <a @click="logout()" class="cursor-pointer"
          ><i class="fa-solid fa-right-from-bracket"></i>
          <span class="hidden sm:inline-block">Quitter</span></a
        >
      </li>
    </ul>
  </header>
</template>
<script>
import axios from "axios";
export default {
  name: "Header",
  data() {
    return {
      homelink: "/",
      operationId: undefined,
    };
  },
  async mounted() {
    try {
      let operationId = sessionStorage.getItem("operationId");

      const routename = this.currentRouteName || this.$route.name;
      let currentUrl = window.location.href;
      if (
        currentUrl.includes("/factures/") ||
        currentUrl.includes("/status/")
      ) {
      } else {
        if (!operationId) {
          this.$router.push("/");
        }
      }

      this.operationId = operationId;
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    "$route.params": {
      deep: true,
      async handler(value) {
        if (value._id && value._secu && this.currentRouteName === "Status") {
          let { data: submission } = await axios.get(
            `${process.env.VUE_APP_ROOT_API}/api/v2/submissions/${value._id}?uid=${value._secu}`
          );
          this.operationId = submission.operationId;
        }
      },
    },
    currentRouteName: {
      immediate: true,
      handler() {
        const id = sessionStorage.getItem("_id");
        const uid = sessionStorage.getItem("uid");
        const operationId = sessionStorage.getItem("operationId");
        if (operationId) this.operationId = operationId;

        if (
          (id && uid && uid !== null && id !== null) ||
          (this.$route.params._id && this.$route.params.secu)
        ) {
          if (this.operationId == "6601a73bec4decac48184437") {
            this.homelink = `/factures/${id || this.$route.params._id}/${
              uid || this.$route.params.secu
            }`;
          } else {
            this.homelink = `/status/${id || this.$route.params._id}/${
              uid || this.$route.params.secu
            }`;
          }
        }
      },
    },
  },
  methods: {
    logout() {
      sessionStorage.clear();
      window.location.href = "/login";
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
